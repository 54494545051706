export default [
  {
    id: 1,
    title: "App Feed",
    description: "App Feed Project.",
    path: "/appfeed",
  },
  {
    id: 2,
    title: "Adventure Sport Travel",
    description: "Adventure Sports Travel Project.",
    path: "/adventuresports",
  },
];
