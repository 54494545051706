import React from "react";
import AboutBox from "../components/About/AboutBox";
import home from "../data/Home";
import { UserTopic } from "../styles/indexStyle.js";
import Layout from "../components/layout";

const IndexPage = () => {
  return (
    <Layout noBackground>
      <UserTopic>
        {home.map((item) => (
          <AboutBox key={item.id} info={item} />
        ))}
      </UserTopic>
    </Layout>
  );
};

export default IndexPage;
